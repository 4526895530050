<template>
    <div class="step">
        <step-header :step-title="trans.step.titles.stepTitle" :catch-phrase="trans.step.titles.catchPhrase"/>

        <div class="form">
            <!-- 1. Has own label -->
            <div class="form__pre-group">
                <div class="util--flex">
                    <h4>{{ trans.step.ownLabel.title }}</h4>
                    <tooltip v-if="trans.step.ownLabel.tooltip.isEnable" :title="trans.step.ownLabel.tooltip.title" :content="trans.step.ownLabel.tooltip.description"/>
                </div>
                <span class="form__pre-group__info">{{ trans.step.ownLabel.clarification }}</span>
            </div>
            <div class="form__group" :class="{'form__error': validation.errors.hasOwnLabel.value}">
                <div class="form__selectable">
                    <!-- Yes -->
                    <div class="form__selectable-item">
                        <label for="hasOwnLabel_yes">
                            <input v-model="marketingCommunicationStep.hasOwnLabel.value" id="hasOwnLabel_yes" type="radio" :value="1">
                            <span>{{ trans.yes }}</span>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            </i>
                        </label>
                    </div>
                    <!-- No -->
                    <div class="form__selectable-item">
                        <label for="hasOwnLabel_no">
                            <input v-model="marketingCommunicationStep.hasOwnLabel.value" id="hasOwnLabel_no" type="radio" :value="0">
                            <span>{{ trans.no }}</span>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            </i>
                        </label>
                    </div>
                    <!-- Don't want any -->
                    <div class="form__selectable-item">
                        <label for="hasOwnLabel_dontWant">
                            <input v-model="marketingCommunicationStep.hasOwnLabel.value" id="hasOwnLabel_dontWant" type="radio" :value="-1">
                            <span>{{ trans.step.ownLabel.choiceNoLabel }}</span>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            </i>
                        </label>
                    </div>
                </div>
                <span v-if="validation.errors.hasOwnLabel.value" class="form__error-text">{{ validation.errors.hasOwnLabel.value }}</span>
            </div>
            <!-- Sub question => Ready to print -->
            <div v-if="marketingCommunicationStep.hasOwnLabel.value === 0">
                <div class="form__pre-group">
                    <span class="form__pre-group__info">{{ trans.step.ownLabel.readyToPrintLabel }}</span>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.hasOwnLabel.isReadyToPrint}">
                    <div class="form__selectable">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="readyToPrint_yes">
                                <input v-model="marketingCommunicationStep.hasOwnLabel.isReadyToPrint" id="readyToPrint_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="readyToPrint_no">
                                <input v-model="marketingCommunicationStep.hasOwnLabel.isReadyToPrint" id="readyToPrint_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.hasOwnLabel.isReadyToPrint" class="form__error-text">{{ validation.errors.hasOwnLabel.isReadyToPrint }}</span>
                </div>
            </div>
            <!-- Sub sub question => need designer service -->
            <div v-if="marketingCommunicationStep.hasOwnLabel.isReadyToPrint === false">
                <div class="form__pre-group">
                    <span class="form__pre-group__info">{{ trans.step.ownLabel.needDesignerLabel }}</span>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.hasOwnLabel.needDesigner}">
                    <div class="form__selectable">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="needDesigner_yes">
                                <input v-model="marketingCommunicationStep.hasOwnLabel.needDesigner" id="needDesigner_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="needDesigner_no">
                                <input v-model="marketingCommunicationStep.hasOwnLabel.needDesigner" id="needDesigner_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.hasOwnLabel.needDesigner" class="form__error-text">{{ validation.errors.hasOwnLabel.needDesigner }}</span>
                </div>
            </div>

            <!-- 2. Need legal validation -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.legalValidation.title }}</h4>
                        <tooltip v-if="trans.step.legalValidation.tooltip.isEnable" :title="trans.step.legalValidation.tooltip.title" :content="trans.step.legalValidation.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.needLegalValidation }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="needLegalValidation_yes">
                                <input v-model="marketingCommunicationStep.needLegalValidation" id="needLegalValidation_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="needLegalValidation_no">
                                <input v-model="marketingCommunicationStep.needLegalValidation" id="needLegalValidation_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.needLegalValidation" class="form__error-text">{{ validation.errors.needLegalValidation }}</span>
                </div>
            </div>
            <!-- 3. Need website -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.website.title }}</h4>
                        <tooltip v-if="trans.step.website.tooltip.isEnable" :title="trans.step.website.tooltip.title" :content="trans.step.website.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.needWebsite }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="needWebsite_yes">
                                <input v-model="marketingCommunicationStep.needWebsite" id="needWebsite_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="needWebsite_no">
                                <input v-model="marketingCommunicationStep.needWebsite" id="needWebsite_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.needWebsite" class="form__error-text">{{ validation.errors.needWebsite }}</span>
                </div>
            </div>
            <!-- 4. Need visual content -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.visualContent.title }}</h4>
                        <tooltip v-if="trans.step.visualContent.tooltip.isEnable" :title="trans.step.visualContent.tooltip.title" :content="trans.step.visualContent.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.needVisualContent.value }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="needVisualContent_yes">
                                <input v-model="marketingCommunicationStep.needVisualContent.value" id="needVisualContent_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="needVisualContent_no">
                                <input v-model="marketingCommunicationStep.needVisualContent.value" id="needVisualContent_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.needVisualContent.value" class="form__error-text">{{ validation.errors.needVisualContent.value }}</span>
                </div>
            </div>
            <div v-if="marketingCommunicationStep.needVisualContent.value === true" class="form__group" :class="{'form__error': validation.errors.needVisualContent.types}">
                <div class="form__pre-group form__pre-group__info">{{ trans.step.visualContent.typeLabel }}</div>
                <div class="form__selectable">
                    <!-- Visual content types -->
                    <div v-for="(visualContent, i) in trans.step.visualContent.types" class="form__selectable-item">
                        <label :for="'visualContentType_'+i">
                            <input v-model="marketingCommunicationStep.needVisualContent.types" :id="'visualContentType_'+i" type="checkbox" :value="visualContent">
                            <span>{{ visualContent }}</span>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            </i>
                        </label>
                    </div>
                </div>
                <span v-if="validation.errors.needVisualContent.types" class="form__error-text">{{ validation.errors.needVisualContent.types }}</span>
            </div>
            <!-- 5. Need digital marketing -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.digitalMarketing.title }}</h4>
                        <tooltip v-if="trans.step.digitalMarketing.tooltip.isEnable" :title="trans.step.digitalMarketing.tooltip.title" :content="trans.step.digitalMarketing.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.needDigitalMarketing }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="needDigitalMarketing_yes">
                                <input v-model="marketingCommunicationStep.needDigitalMarketing" id="needDigitalMarketing_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="needDigitalMarketing_no">
                                <input v-model="marketingCommunicationStep.needDigitalMarketing" id="needDigitalMarketing_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.needDigitalMarketing" class="form__error-text">{{ validation.errors.needDigitalMarketing }}</span>
                </div>
            </div>
        </div>

        <step-footer @nextClicked="validate"/>
    </div>
</template>

<script>
import StepFooter from "@/create-my-drink/components/StepFooter";
import StepHeader from "@/create-my-drink/components/StepHeader";
import {validateStep} from "@/create-my-drink/lib";
import * as Yup from "yup";
import Tooltip from "@/create-my-drink/components/Tooltip";

export default {
    name: "MarketingCommunication",
    components: {StepHeader, StepFooter, Tooltip},
    data() {
        return {
            marketingCommunicationStep: this.$store.state.form.steps.marketingCommunication,
            trans: {
                step: this.$store.state.umbracoProps.marketingCommunication,
                validation: this.$store.state.umbracoProps.others.validation,
                yes: this.$store.state.umbracoProps.others.yes,
                no: this.$store.state.umbracoProps.others.no,
            },
            // Properties must have exactly the same name as the value in the form step storage
            validation: {
                validationSchema: null, // Must be generate in mounted() because of data dependencies 
                errors: {
                    hasOwnLabel: {
                        value: null,
                        isReadyToPrint: null,
                        needDesigner: null
                    },
                    needLegalValidation: null,
                    needWebsite: null,
                    needVisualContent: {
                        value: null,
                        types: null
                    },
                    needDigitalMarketing: null
                }
            },
        }
    },
    mounted() {
        window.scrollTo({
            top: 0
        })
        
        // generate the validation schema that have some data() dependencies 
        this.validation.validationSchema = Yup.object().shape({
            hasOwnLabel: Yup.object().shape({
                value: Yup.number().nullable()
                    .oneOf([0, 1, -1], this.trans.validation.required),
                isReadyToPrint: Yup.boolean().nullable().when("value", {
                    is: false,
                    then: Yup.boolean().nullable()
                        .required(this.trans.validation.required),
                }),
                needDesigner: Yup.boolean().nullable().when("isReadyToPrint", {
                    is: false,
                    then: Yup.boolean().nullable()
                        .required(this.trans.validation.required),
                })
            }),
            needLegalValidation: Yup.boolean().nullable()
                .required(this.trans.validation.required),
            needWebsite: Yup.boolean().nullable()
                .required(this.trans.validation.required),
            needVisualContent: Yup.object().shape({
                value: Yup.boolean().nullable()
                    .required(this.trans.validation.required),
                types: Yup.array().when('value', {
                    is: true,
                    then: Yup.array().of(Yup.string()).min(1, this.trans.validation.selectOneMin)
                })
            }),
            needDigitalMarketing: Yup.boolean().nullable()
                .required(this.trans.validation.required),
        })
    },
    methods: {
        validate: function() {
            validateStep(this.validation.validationSchema, this.marketingCommunicationStep, this.validation.errors, () => {
                // Store + persist data and pass to the next step at the same time
                this.$store.commit('nextStep')
            })
        }
    },
}
</script>

<style scoped>

</style>