<template>
    <div class="step">
        
        <div class="util--hide-print">
            <div ref="stickySendBtn" class="sendBtn--sticky util--hide-for-small-down">
                <button v-if="!sendEmail.alreadySent && !sendEmail.isSending" @click="sendFromSticky" class="btn btn--white">{{ trans.step.summaryEstimate.sendLabel }} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16"><path d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z"/></svg></button>
            </div>
            
            <div class="summaryStep__header">
                <h3 v-if="sendEmail.sentSuccessfully">{{ trans.step.summaryEstimate.successMessage }}</h3>
                <h3 v-else>{{ trans.step.summaryEstimate.title }}</h3>
                <div class="summaryStep__header-buttons">
                    <!--
                    <button @click="print" class="btn--print">{{ trans.step.summaryEstimate.printLabel }} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zm-16-88c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"/></svg></button>
                    -->
                    <button v-if="!sendEmail.alreadySent && !sendEmail.isSending" @click="send" id="sendBtn" class="btn--share">{{ trans.step.summaryEstimate.sendLabel }} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16"><path d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z"/></svg></button>
                    <loader v-if="sendEmail.isSending" class="loader"/>
                    <span v-if="sendEmail.sentErrorOccurred" class="summaryStep__header-label--error">{{ trans.step.summaryEstimate.errorMessage }}</span>
                </div>
            </div>
    
            <div class="accordionItems">
                <!-- 1. General step -->
                <accordion-item :number="1" :title="trans.step.general.titles.stepTitle">
                    <!-- 1. Who are you -->
                    <div class="fieldsetItem">
                        <div class="grid__row grid__row--jcc">
                            <div class="grid__col--sm-6 grid__col--xs-12">
                                <div>
                                    <div class="fieldsetItem__label">
                                        {{ trans.step.general.labelLastname }} / {{ trans.step.general.labelFirstname }}
                                    </div>
                                    <div class="fieldsetItem__inputResponse">
                                        {{ generalStep.lastname + " " + generalStep.firstname }}
                                    </div>
                                </div>
                                <div>
                                    <div class="fieldsetItem__label">
                                        {{ trans.step.general.labelEmail }}
                                    </div>
                                    <div class="fieldsetItem__inputResponse">
                                        {{ generalStep.email }}
                                    </div>
                                </div>
                            </div>
                            <div class="grid__col--sm-6 grid__col--xs-12">
                                <div>
                                    <div class="fieldsetItem__label">
                                        {{ trans.step.general.labelBrandName }}
                                    </div>
                                    <div class="fieldsetItem__inputResponse">
                                        {{ generalStep.brandName}}
                                    </div>
                                </div>
                                <div>
                                    <div class="fieldsetItem__label">
                                        {{ trans.step.general.labelPhone }}
                                    </div>
                                    <div class="fieldsetItem__inputResponse">
                                        {{ generalStep.phone }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </accordion-item>
                
                <!-- 1. About drink step -->
                <accordion-item :number="2" :title="trans.step.aboutDrink.titles.stepTitle">
                    <!-- 1. Drink type -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.aboutDrink.drinkType.title }}</h4>
                            <selectable-item-checked :bool-value="false" :value="aboutDrinkStep.drinkType.value"/>
                        </div>
                        <div v-if="aboutDrinkStep.drinkType.otherValue !== null" class="fieldsetItem__inputResponse">
                            {{ aboutDrinkStep.drinkType.otherValue  }}
                        </div>
                    </div>
                    <!-- 2. Flavors -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.aboutDrink.multipleFlavours.title }}</h4>
                            <selectable-item-checked :value="aboutDrinkStep.hasMultipleFlavor"/>
                        </div>
                    </div>
                    <!-- 3. Gaseous -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.aboutDrink.isGaseous.title }}</h4>
                            <selectable-item-checked :value="aboutDrinkStep.isGaseous"/>
                        </div>
                    </div>
                    <!-- 4. Raw material -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.aboutDrink.rawMaterial.title }}</h4>
                            <selectable-item-checked :value="aboutDrinkStep.hasRawMaterial.value"/>
                        </div>
                        <div v-if="aboutDrinkStep.hasRawMaterial.value === false">
                            <div class="fieldsetItem__label">
                                {{ trans.step.aboutDrink.rawMaterial.wantedValue }}
                            </div>
                            <div class="fieldsetItem__inputResponse">
                                {{ aboutDrinkStep.hasRawMaterial.wantedValue  }}
                            </div>
                        </div>
                    </div>
                    <!-- 5. Recipe -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.aboutDrink.recipe.title }}</h4>
                            <selectable-item-checked :value="aboutDrinkStep.hasRecipe.value"/>
                        </div>
                        <div v-if="aboutDrinkStep.hasRecipe.value === true" class="fieldsetItem__inputResponse">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg> {{ aboutDrinkStep.hasRecipe.uploadedRecipe.name  }}
                        </div>
                    </div>
                    <!-- 6. Testing panel -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.aboutDrink.panel.title }}</h4>
                            <selectable-item-checked :value="aboutDrinkStep.needTestingPanel"/>
                        </div>
                    </div>
                    <!-- 7. Laboratory analysis -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.aboutDrink.analysis.title }}</h4>
                            <selectable-item-checked :value="aboutDrinkStep.hasLaboratoryAnalysis"/>
                        </div>
                    </div>
                    <!-- 8. Certifications -->
                    <div class="fieldsetItem">
                        <h4>{{ trans.step.aboutDrink.certifications.title }}</h4>
                        <div v-if="aboutDrinkStep.neededCertifications.length > 0" class="util--flex util--mt-small">
                            <selectable-item-checked v-for="item in aboutDrinkStep.neededCertifications" :bool-value="false" :value="item"/>
                        </div>
                        <div v-else class="fieldsetItem__label">
                            {{ trans.step.aboutDrink.certifications.none }}
                        </div>
                    </div>
                </accordion-item>
    
                <!-- 2. Production and Logistics step -->
                <accordion-item :number="3" :title="umbracoProps.productionLogistics.titles.stepTitle" >
                    <!-- 1. Bottles quantity -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.productionLogistics.bottles.title }}</h4>
                            <selectable-item-checked :bool-value="false" :value="fetchBottlesQuantityLabel"/>
                        </div>
                    </div>
                    <!-- 2. Container type -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.productionLogistics.container.title }}<span style="font-size: 20px;color: red">TODO, waiting types?</span></h4>
                        </div>
                    </div>
                    <!-- 3. Storage raw material -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.productionLogistics.materialStorage.title }}</h4>
                            <selectable-item-checked :value="productionLogisticsStep.needStorageRawMaterial.value"/>
                        </div>
                        <div v-if="productionLogisticsStep.needStorageRawMaterial.value === true">
                            <div>
                                <selectable-item-checked :bool-value="false" :value="productionLogisticsStep.needStorageRawMaterial.temperatureType"/>
                            </div>
                        </div>
                    </div>
                    <!-- 4. Storage final product -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.productionLogistics.productStorage.title }}</h4>
                            <selectable-item-checked :value="productionLogisticsStep.needStorageFinalProduct.value"/>
                        </div>
                        <div v-if="productionLogisticsStep.needStorageFinalProduct.value === true">
                            <div>
                                <selectable-item-checked :bool-value="false" :value="productionLogisticsStep.needStorageFinalProduct.temperatureType"/>
                            </div>
                        </div>
                    </div>
                    <!-- 5. Transportation partner -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.productionLogistics.transport.title }}</h4>
                            <selectable-item-checked :value="productionLogisticsStep.needTransportationPartner"/>
                        </div>
                    </div>
                    <!-- 6. Nutritional values -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.productionLogistics.nutrition.title }}</h4>
                            <selectable-item-checked :value="productionLogisticsStep.needNutritionalValue"/>
                        </div>
                    </div>
                </accordion-item>
    
                <!-- 3. Marketing communication step -->
                <accordion-item :number="4" :title="umbracoProps.marketingCommunication.titles.stepTitle" >
                    <!-- 1. Own label -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.marketingCommunication.ownLabel.title }}</h4>
                            <selectable-item-checked :bool-value="false" :value="marketingCommunicationStep.hasOwnLabel.value === -1 ? trans.step.marketingCommunication.ownLabel.choiceNoLabel : marketingCommunicationStep.hasOwnLabel.value === 0 ? trans.no : trans.yes"/>
                        </div>
                        <div v-if="marketingCommunicationStep.hasOwnLabel.value === 0">
                            <div class="fieldsetItem__label">
                                {{ trans.step.marketingCommunication.ownLabel.readyToPrintLabel }}
                            </div>
                            <div>
                                <selectable-item-checked :value="marketingCommunicationStep.hasOwnLabel.isReadyToPrint"/>
                            </div>
                            <div v-if="marketingCommunicationStep.hasOwnLabel.isReadyToPrint === false">
                                <div class="fieldsetItem__label">
                                    {{ trans.step.marketingCommunication.ownLabel.needDesignerLabel }}
                                </div>
                                <div>
                                    <selectable-item-checked :value="marketingCommunicationStep.hasOwnLabel.needDesigner"/>
                                </div>
    
                            </div>
                        </div>
                    </div>
                    <!-- 2. Legal validation -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.marketingCommunication.legalValidation.title }}</h4>
                            <selectable-item-checked :value="marketingCommunicationStep.needLegalValidation"/>
                        </div>
                    </div>
                    <!-- 3. Website / Shop -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.marketingCommunication.website.title }}</h4>
                            <selectable-item-checked :value="marketingCommunicationStep.needWebsite"/>
                        </div>
                    </div>
                    <!-- 4. Visual content -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.marketingCommunication.visualContent.title }}</h4>
                            <selectable-item-checked :value="marketingCommunicationStep.needVisualContent.value"/>
                        </div>
                        <div v-if="marketingCommunicationStep.needVisualContent.value === true">
                            <div class="util--flex util--mt-small">
                                <selectable-item-checked v-for="type in marketingCommunicationStep.needVisualContent.types" :bool-value="false" :value="type"/>
                            </div>
                        </div>
                    </div>
                    <!-- 5. Digital marketing -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.marketingCommunication.digitalMarketing.title }}</h4>
                            <selectable-item-checked :value="marketingCommunicationStep.needDigitalMarketing"/>
                        </div>
                    </div>
                </accordion-item>
    
                <!-- 3. Investment incubation step -->
                <accordion-item :number="5" :title="umbracoProps.investmentIncubation.titles.stepTitle">
                    <!-- 1. Investment -->
                    <div class="fieldsetItem">
                        <div class="util--flex util--flex-Sb">
                            <h4>{{ trans.step.investmentIncubation.investment.title }}</h4>
                            <selectable-item-checked :value="investmentIncubationStep.needInvestment.value"/>
                        </div>
                        <div v-if="investmentIncubationStep.needInvestment.value === true">
    
                            <div class="fieldsetItem__label">
                                {{ trans.step.investmentIncubation.investment.amountNeededLabel }}
                            </div>
                            <div v-if="investmentIncubationStep.needInvestment.dontKnowAmount === false" class="fieldsetItem__inputResponse">
                                {{ investmentIncubationStep.needInvestment.amount }}
                            </div>
                            <selectable-item-checked v-else :bool-value="false" :value="trans.step.investmentIncubation.investment.choiceDontKnow"/>
    
                            <div class="fieldsetItem__label">
                                {{ trans.step.investmentIncubation.investment.capitalLabel }}
                            </div>
                            <selectable-item-checked :value="investmentIncubationStep.needInvestment.capitalParticipation"/>
                        </div>
                    </div>
                </accordion-item>
            </div>
        </div>

        <SummaryEstimatePrint/>

        <price-summary ref="priceSummary"/>
    </div>
</template>

<script>

import {AVAILABLE_BOTTLES_QUANTITY} from "@/create-my-drink/lib";
import PriceSummary from "@/create-my-drink/components/PriceSummary";
import AccordionItem from "@/create-my-drink/components/AccordionItem";
import SelectableItemChecked from "@/create-my-drink/components/SelectableItemChecked";
import axios from "axios";
import SummaryEstimatePrint from "@/create-my-drink/steps/SummaryEstimatePrint";
import Loader from "@/create-my-drink/components/Loader";

export default {
    name: "SummaryEstimate",
    components: {SummaryEstimatePrint, SelectableItemChecked, AccordionItem, PriceSummary, Loader},
    data() {
        return {
            generalStep: this.$store.state.form.steps.general,
            aboutDrinkStep: this.$store.state.form.steps.aboutDrink,
            productionLogisticsStep: this.$store.state.form.steps.productionLogistics,
            marketingCommunicationStep: this.$store.state.form.steps.marketingCommunication,
            investmentIncubationStep: this.$store.state.form.steps.investmentIncubation,
            umbracoProps: this.$store.state.umbracoProps,
            trans: {
                step: {
                    general: this.$store.state.umbracoProps.general,
                    aboutDrink: this.$store.state.umbracoProps.aboutDrink,
                    productionLogistics: this.$store.state.umbracoProps.productionLogistics,
                    marketingCommunication: this.$store.state.umbracoProps.marketingCommunication,
                    investmentIncubation: this.$store.state.umbracoProps.investmentIncubation,
                    summaryEstimate: this.$store.state.umbracoProps.summaryEstimate,
                },
                yes: this.$store.state.umbracoProps.others.yes,
                no: this.$store.state.umbracoProps.others.no,
            },
            sendEmail: {
                isSending: false,
                sentSuccessfully: false,
                sentErrorOccurred: false,
                alreadySent: false
            }
        }
    },
    inject: ['steps'],
    mounted() {
        window.scrollTo({
            top: 0
        })

        // Show the cursor when printing window has been closed
        window.addEventListener('afterprint', this.handleAfterPrint)
        // Appear / disappear the sticky send button relative to the current scroll
        window.addEventListener('scroll', this.onScrollStickSendBtn)
    },
    beforeUnmount() {
        window.removeEventListener('afterprint', this.handleAfterPrint)
        window.removeEventListener('scroll', this.onScrollStickSendBtn)
    },
    computed: {
        fetchBottlesQuantityLabel: function() {
            return AVAILABLE_BOTTLES_QUANTITY.find(item => item.value === this.productionLogisticsStep.bottlesQuantity).name
        }
    },
    methods: {
        handleAfterPrint: function() {
            document.querySelector('.cursor').style.display = 'initial'
        },
        print: function() {
            // Hide the cursor and open printing window
            document.querySelector('.cursor').style.display = 'none'
            window.print()
        },
        onScrollStickSendBtn: function () {
            if (document.documentElement.scrollTop > 250 || document.body.scrollTop > 250) {
                this.$refs.stickySendBtn.style.right = '20px'
            }else{
                
                this.$refs.stickySendBtn.style.right = '-300px'
            }
        },
        /**
         * Send the form from the sticky button
         * 1. come back to the top of the page, and send the form. This way the user can see 
         * success or error message
         */
        sendFromSticky: function() {
            window.scrollTo({
                top: 0
            })
            
            this.send()
        },
        /**
         * Send the whole form to the backend
         */
        send: function() {
            const appEl = document.getElementById("app");
            
            this.sendEmail.sentSuccessfully = false
            this.sendEmail.sentErrorOccurred = false
            this.sendEmail.isSending = true
            
            const lang = appEl.lang === "" ? "fr" : appEl.lang;
            
            axios.post('/umbraco/api/CreateDrink/SendForm', {
                "lang": lang,
                "general": this.generalStep,
                "aboutDrink": this.aboutDrinkStep,
                "productionLogistics": this.productionLogisticsStep,
                "marketingCommunication": this.marketingCommunicationStep,
                "investmentIncubation": this.investmentIncubationStep,
                "costs": {
                    "fixed": this.$refs.priceSummary.fixedCosts.activeItems,
                    "variablePerBottle": this.$refs.priceSummary.pricePerBottleRounded,
                    "total": this.$refs.priceSummary.totalCosts
                }
            })
                .then((res) => {
                    if(res.data === true) {
                        this.sendEmail.sentSuccessfully = true
                        this.sendEmail.alreadySent = true
                    }else {
                        this.sendEmail.sentErrorOccurred = true
                    }
                })
                .catch(() => {
                    this.sendEmail.sentErrorOccurred = true
                })
                .finally(() => {
                    this.sendEmail.isSending = false
                })
             
        }
    }
}
</script>

<style scoped>

</style>