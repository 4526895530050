<template>
    <div class="step">
        <step-header :step-title="trans.step.titles.stepTitle" :catch-phrase="trans.step.titles.catchPhrase"/>
        
        <div class="form">
            <!-- 1. need investment -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.investment.title }}</h4>
                        <tooltip v-if="trans.step.investment.tooltip.isEnable" :title="trans.step.investment.tooltip.title" :content="trans.step.investment.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.needInvestment.value }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="needInvestment_yes">
                                <input v-model="investmentIncubationStep.needInvestment.value" id="needInvestment_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="needInvestment_no">
                                <input v-model="investmentIncubationStep.needInvestment.value" id="needInvestment_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.needInvestment.value" class="form__error-text">{{ validation.errors.needInvestment.value }}</span>
                </div>
            </div>
            <div v-if="investmentIncubationStep.needInvestment.value === true" class="form__group" :class="{'form__error': validation.errors.needInvestment.value || validation.errors.needInvestment.amount || validation.errors.needInvestment.capitalParticipation }">
                
                <!-- 1.1 Amount-->
                <div class="form__pre-group">
                    <span class="form__pre-group__info">{{ trans.step.investment.amountNeededLabel }}</span>
                </div>
                <div class="grid__row grid__row--aic">
                    <div class="grid__col--sm-4 grid__col-12">
                        <!-- Amount number -->
                        <div class="form__group">
                            <input v-model.number="investmentIncubationStep.needInvestment.amount" id="investmentAmount" type="number" placeholder="Montant" min="1">
                            <label for="investmentAmount">{{ trans.step.investment.amountLabel }}</label>
                        </div>
                        <span v-if="validation.errors.needInvestment.amount" class="form__error-text">{{ validation.errors.needInvestment.amount }}</span>
                    </div>
                    
                    <!-- Don't know amount -->
                    <div class="amountDontKnow">
                        <!-- Don't know -->
                        <div class="form__selectable">
                            <div class="form__selectable-item">
                                <label for="amountDontKnow">
                                    <input v-model="investmentIncubationStep.needInvestment.dontKnowAmount" id="amountDontKnow" type="checkbox" :value="true">
                                    <span>{{ trans.step.investment.choiceDontKnow }}</span>
                                    <i>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                    </i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- 1.2 Own participation -->
                <div class="form__pre-group">
                    <span class="form__pre-group__info">{{ trans.step.investment.capitalLabel }}</span>
                </div>
                <div class="form__selectable">
                    <!-- Yes -->
                    <div class="form__selectable-item">
                        <label for="capitalParticipation_yes">
                            <input v-model="investmentIncubationStep.needInvestment.capitalParticipation" id="capitalParticipation_yes" type="radio" :value="true">
                            <span>{{ trans.yes }}</span>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            </i>
                        </label>
                    </div>
                    <!-- No -->
                    <div class="form__selectable-item">
                        <label for="capitalParticipation_no">
                            <input v-model="investmentIncubationStep.needInvestment.capitalParticipation" id="capitalParticipation_no" type="radio" :value="false">
                            <span>{{ trans.no }}</span>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            </i>
                        </label>
                    </div>
                </div>
                <span v-if="validation.errors.needInvestment.capitalParticipation" class="form__error-text">{{ validation.errors.needInvestment.capitalParticipation }}</span>
            </div>
        </div>

        <step-footer @nextClicked="validate"/>
    </div>
</template>

<script>
import StepFooter from "@/create-my-drink/components/StepFooter";
import StepHeader from "@/create-my-drink/components/StepHeader";
import * as Yup from "yup";
import {validateStep} from "@/create-my-drink/lib";
import Tooltip from "@/create-my-drink/components/Tooltip";

export default {
    name: "InvestmentIncubation",
    components: {StepHeader, StepFooter, Tooltip},
    data() {
        return {
            investmentIncubationStep: this.$store.state.form.steps.investmentIncubation,
            trans: {
                step: this.$store.state.umbracoProps.investmentIncubation,
                validation: this.$store.state.umbracoProps.others.validation,
                yes: this.$store.state.umbracoProps.others.yes,
                no: this.$store.state.umbracoProps.others.no,
            },
            // Properties must have exactly the same name as the value in the form step storage
            validation: {
                validationSchema: null, // Must be generate in mounted() because of data dependencies 
                errors: {
                    needInvestment: {
                        value: null,
                        amount: null
                    }
                }
            },
        }
    },
    mounted() {
        window.scrollTo({
            top: 0
        })
        
        // generate the validation schema that have some data() dependencies 
        this.validation.validationSchema = Yup.object().shape({
            needInvestment: Yup.object().shape({
                value: Yup.boolean().nullable()
                    .required(this.trans.validation.required),
                amount: Yup.mixed().nullable().when('value', {
                    is: true,
                    then: Yup.mixed().nullable()
                        .test("amountOrDontKnow", this.trans.validation.required, (val) => { return this.investmentIncubationStep.needInvestment.dontKnowAmount === true || val > 0 })
                }),
                capitalParticipation: Yup.boolean().nullable().when('value', {
                    is: true,
                    then: Yup.boolean().nullable()
                        .required(this.trans.validation.required)
                })
            })
        })
    },
    methods: {
        validate: function() {
            validateStep(this.validation.validationSchema, this.investmentIncubationStep, this.validation.errors, () => {
                // Store + persist data and pass to the next step at the same time
                this.$store.commit('nextStep')
            })
        }
    },
}
</script>

<style scoped>

</style>