<template>
    <div class="dropzone" 
         @dragenter.prevent="toggleActive" 
         @dragleave.prevent="toggleActive"
         @dragover.prevent
         @drop.prevent="toggleActive">
        <label for="dropzoneFile" :class="{'dropzone--active': active}">
            <span class="dropzone__label">{{ label }} <span v-if="uploadedFileName !== null" class="dropzone__label--droppedFile">{{ uploadedFileName }}</span></span>
            <input type="file" id="dropzoneFile">
        </label>
    </div>
</template>

<script>
export default {
    name: "DropZone",
    props: {
        label: String,
        uploadedFileName: String
    },
    data() {
        return {
            active: false
        }
    },
    methods: {
        toggleActive: function () {
            this.active = !this.active
        }
    }
}
</script>

<style scoped>

</style>