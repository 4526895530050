<template>
    <div class="tooltip">
        <span class="tooltip__icon">
            <svg @click="toggle" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256s114.6 256 256 256zm-40-176h24v-64h-24c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-80c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"/></svg>
        </span>
        <div class="tooltip__contentBox" :class="isOpen ? 'tooltip--open' : 'tooltip--close'">
            <div class="tooltip__contentBox-title">
                <span @click="toggle">Fermer <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="16" height="16"><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/></svg></span>
            </div>
            <div class="tooltip__contentBox-content">
                <div class="tooltip__contentBox-contentTitle">{{ title }}</div>
                <div class="tooltip__contentBox-contentText" v-html="content"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tooltip",
    props: {
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        toggle: function() {
            this.isOpen = !this.isOpen
        }
    }
}
</script>

<style scoped>

</style>