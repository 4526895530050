﻿
export const AVAILABLE_BOTTLES_QUANTITY = [
    {
        id: 1,
        idStr: 'minus2k',
        name: "< 2'000",
        value: 0,
    },
    {
        id: 2,
        idStr: '2k5k',
        name: "2'000 - 5'000",
        value: 2000,
    },
    {
        id: 3,
        idStr: '5k10k',
        name: "5'000 - 10'000",
        value: 5000,
    },
    {
        id: 4,
        idStr: '10k15k',
        name: "10'000 - 15'000",
        value: 10000,
    },
    {
        id: 5,
        idStr: '15k20k',
        name: "15'000 - 20'000",
        value: 15000,
    },
    {
        id: 6,
        idStr: 'plus15k',
        name: "> 20'000",
        value: 20000,
    }
];

/**
 * Validate a Yup validation schema according some data
 * @param validationSchema The yup validation schema
 * @param data The whole data object to validate
 * @param errors The whole errors objects that will be updated in case of errors
 * @param successCallback A callback function that will be called in case of success
 */
export function validateStep(validationSchema, data, errors, successCallback) {
    clearNestedPropertiesOfObject(errors)
    
    // validate all values of the current step
    validationSchema.validate(data, { abortEarly: false })
        .then(() => {
            successCallback()
        })
        .catch(err => {
            // Fill the errors array with nested properties
            err.inner.forEach(error => {
                const properties = error.path.split('.')
                const lastProperty = properties.pop()
                
                let currentObj = errors
                properties.forEach(property => {
                    currentObj = currentObj[property]
                })
                currentObj[lastProperty] = error.message
            });
        })
}

/**
 * Return the right bottle quantity object that match the current stored quantity value
 * @param quantity The quantity value
 * @returns {{idStr: string, name: string, id: number, value: number} | {idStr: string, name: string, id: number, value: number} | {idStr: string, name: string, id: number, value: number} | {idStr: string, name: string, id: number, value: number} | {idStr: string, name: string, id: number, value: number}}
 */
export function getBottleQuantityObjFromQuantity(quantity) {
    return AVAILABLE_BOTTLES_QUANTITY.find(item => item.value === quantity)
}

/**
 * Set all properties and nested properties of an object to null
 * @param obj The object we want to clear
 */
function clearNestedPropertiesOfObject(obj) {
    Object.keys(obj).forEach(key => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            clearNestedPropertiesOfObject(obj[key])
        }else if(obj[key] != null) {
            obj[key] = null;
        }
    })
}