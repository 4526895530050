<template>
    <div class="accordionItem" :class="isOpen ? 'accordionItem--open' : 'accordionItem--close'">
        <div @click="toggleOpen" class="accordionItem__header">
            <div class="accordionItem__header-number">{{ number }}</div>
            <div class="accordionItem__header-title">
                <span>{{ title }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
            </div>
        </div>
        <div class="accordionItem__content">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "AccordionItem",
    props: {
        number: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        toggleOpen: function() {
            this.isOpen = !this.isOpen
            this.$emit('toggled')
        }
    }
}
</script>

<style scoped>

</style>