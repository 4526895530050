<template>
    <div class="form__selectable-item">
        <label>
            <input :type="isRadio ? 'radio' : 'checkbox'" checked>
            <span>
                {{ 
                    boolValue 
                        ? value === true ? trans.yes : trans.no
                        : value
                }}
            </span>
            <i>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
            </i>
        </label>
    </div>
</template>

<script>
export default {
    name: "SelectableItemChecked",
    props: {
        isRadio: {
            type: Boolean, // True = radio, False = checkbox
            default: true
        },
        boolValue: {
            type: Boolean,
            default: true
        },
        value: {
            type: [String, Boolean],
            required: true
        }
    },
    data() {
        return {
            trans: {
                yes: this.$store.state.umbracoProps.others.yes,
                no: this.$store.state.umbracoProps.others.no,
            },
        }
    }
}
</script>

<style scoped>

</style>