import {createStore} from "vuex";
import { getBottleQuantityObjFromQuantity} from "@/create-my-drink/lib";

const STORAGE = sessionStorage
const PERSISTED_STATE_NAME = 'createMyDrink'
const EMPTY_FORM = {
    currentStep: 0,
    steps: {
        general: {
            lastname: null,
            firstname: null,
            email: null,
            brandName: null,
            phone: null
        },
        aboutDrink: {
            drinkType: {
                value: null,
                otherValue: null
            },
            hasMultipleFlavor: null,
            isGaseous: null,
            hasRawMaterial: {
                value: null,
                wantedValue: null
            },
            hasRecipe: {
                value: null,
                uploadedRecipe: {
                    name: null,
                    base64Encoded: null
                }
            },
            needTestingPanel: null,
            hasLaboratoryAnalysis: null,
            neededCertifications: []
        },
        productionLogistics: {
            bottlesQuantity: 1000, // default value until the user select a more precise value
            bottlesQuantityStr: null,
            needStorageRawMaterial: {
                value: null,
                temperatureType: null
            },
            needStorageFinalProduct: {
                value: null,
                temperatureType: null
            },
            needTransportationPartner: null,
            needNutritionalValue: null
        },
        marketingCommunication: {
            hasOwnLabel: {
                value: null,
                isReadyToPrint: null,
                needDesigner: null
            },
            needLegalValidation: null,
            needWebsite: null,
            needVisualContent: {
                value: null,
                types: []
            },
            needDigitalMarketing: null
        },
        investmentIncubation: {
            needInvestment: {
                value: null,
                amount: null,
                dontKnowAmount: false,
                capitalParticipation: null
            }
        }
    }
}
// Try to init the local storage if not already done 
initPersistedState(EMPTY_FORM)



const createMyDrinkStore = createStore({
    state () {
        return {
            // The form state data came from the local storage
            form: getPersistedState(),
            umbracoProps: null,
        }
    },
    mutations: {
        /**
         * Increment the current step and save all the current form state
         * into the local storage 
         * @param state The current state object
         */
        nextStep(state) {
            state.form.currentStep++
            setPersistedState(state.form)
        },
        /**
         * Decrement the current step and save all the current form state
         * into the local storage
         * @param state The current state object
         */
        previousStep(state) {
            state.form.currentStep--
            setPersistedState(state.form)
        },
        setUmbracoProps(state, props) {
            state.umbracoProps = props
        }
    },
})





// Watchers on the store to clear selection on "drawer question" when we close the "drawer"
// This is important for price management. E.g: 
// Question1 : If Answer1 === true => open SubQuestion1
// SubQuestion1 : If Answer1.1 === XY => price -0.10
// Question1 : Change Answer1 to false
// SubQuestion1: We need to clear the Answer1.1 to be able to correctly arrange the price etc... (managed in the PriceSummary component, but we need to reset the value)

// About drink step -> DrinkType
createMyDrinkStore.watch(
    // Drink Type
    function(state) {
        return state.form.steps.aboutDrink.drinkType.value
    },(value) => {
        createMyDrinkStore.state.form.steps.aboutDrink.drinkType.otherValue = null
    }
)
// About drink step -> hasRawMaterial
createMyDrinkStore.watch(
    // Raw material
    function(state) {
        return state.form.steps.aboutDrink.hasRawMaterial.value
    },(value) => {
        if(value === true){
            createMyDrinkStore.state.form.steps.aboutDrink.hasRawMaterial.wantedValue = null
        }
    }
)
// About drink step -> hasRecipe
createMyDrinkStore.watch(
    // hasRecipe
    function(state) {
        return state.form.steps.aboutDrink.hasRecipe.value
    },(value) => {
        if(value === false){
            createMyDrinkStore.state.form.steps.aboutDrink.hasRecipe.uploadedRecipe.name = null
            createMyDrinkStore.state.form.steps.aboutDrink.hasRecipe.uploadedRecipe.bytes = null
        }
    }
)
// ProductionLogistics step -> bottlesQuantityStr
createMyDrinkStore.watch(
    function(state) {
        return state.form.steps.productionLogistics.bottlesQuantity
    },(value) => {
        createMyDrinkStore.state.form.steps.productionLogistics.bottlesQuantityStr = getBottleQuantityObjFromQuantity(value).name
    }
)
// ProductionLogistics step -> need raw material storage
createMyDrinkStore.watch(
    // Need storage
    function(state) {
        return state.form.steps.productionLogistics.needStorageRawMaterial.value
    },(value) => {
        if(value === false){
            createMyDrinkStore.state.form.steps.productionLogistics.needStorageRawMaterial.temperatureType = null
        }
    }
)
// ProductionLogistics step -> need final product storage
createMyDrinkStore.watch(
    // Need storage
    function(state) {
        return state.form.steps.productionLogistics.needStorageFinalProduct.value
    },(value) => {
        if(value === false){
            createMyDrinkStore.state.form.steps.productionLogistics.needStorageFinalProduct.temperatureType = null
        }
    }
)
// MarketingCommunication step -> has own label
createMyDrinkStore.watch(
    // Has own label
    function(state) {
        return state.form.steps.marketingCommunication.hasOwnLabel.value
    },(value) => {
        if(value !== false){
            createMyDrinkStore.state.form.steps.marketingCommunication.hasOwnLabel.isReadyToPrint = null
        }
    }
)
// MarketingCommunication step -> has own label
createMyDrinkStore.watch(
    // isReadyToPrint
    function(state) {
        return state.form.steps.marketingCommunication.hasOwnLabel.isReadyToPrint
    },(value) => {
        if(value !== false){
            createMyDrinkStore.state.form.steps.marketingCommunication.hasOwnLabel.needDesigner = null
        }
    }
)
// MarketingCommunication step -> need visual content
createMyDrinkStore.watch(
    // needVisualContent
    function(state) {
        return state.form.steps.marketingCommunication.needVisualContent.value
    },(value) => {
        if(value === false){
            createMyDrinkStore.state.form.steps.marketingCommunication.needVisualContent.types = []
        }
    }
)
// InvestmentIncubation step -> need investment
createMyDrinkStore.watch(
    // needInvestment
    function(state) {
        return state.form.steps.investmentIncubation.needInvestment.value
    },(value) => {
        if(value === false){
            createMyDrinkStore.state.form.steps.investmentIncubation.needInvestment.amount = null
            createMyDrinkStore.state.form.steps.investmentIncubation.needInvestment.dontKnowAmount = false
            createMyDrinkStore.state.form.steps.investmentIncubation.needInvestment.capitalParticipation = null
        }
    }
)




/**
 * Init the form in the localstorage only if it as not been already done
 * @param data Data to store in local storage
 */
function initPersistedState(data) {
    if(getPersistedState() === null) {
        STORAGE.setItem(PERSISTED_STATE_NAME, JSON.stringify(data))
    }
}

/**
 * Fetch the form data from the local storage
 * @returns The current form data object from the local storage
 */
function getPersistedState() {
    const data = STORAGE.getItem(PERSISTED_STATE_NAME)
    return JSON.parse(data)
}

/**
 * Persist the data in local storage
 * @param data The data we want to persist in the local storage
 */
function setPersistedState(data) {
    STORAGE.setItem(PERSISTED_STATE_NAME, JSON.stringify(data));
}



export default createMyDrinkStore