<template>
    <div class="step">
        <step-header :step-title="trans.step.titles.stepTitle" :catch-phrase="trans.step.titles.catchPhrase"/>

        <div class="form">

            <!-- 1. Drink type -->
            <div class="form__pre-group">
                <div class="util--flex">
                    <h4>{{ trans.step.drinkType.title }}</h4>
                    <tooltip v-if="trans.step.drinkType.tooltip.isEnable" :title="trans.step.drinkType.tooltip.title" :content="trans.step.drinkType.tooltip.description"/>
                </div>
                <span class="form__pre-group__info">{{ trans.step.drinkType.clarification }}</span>
            </div>
            <div class="form__group" :class="{'form__error': validation.errors.drinkType.value || validation.errors.drinkType.otherValue}">
                <!-- Available types -->
                <div class="form__selectable">
                    <div v-for="(drinkType, i) in trans.step.drinkType.types" :key="i" class="form__selectable-item">
                        <label :for="'drinkType_'+i">
                            <input :id="'drinkType_'+i" v-model="aboutDrinkStep.drinkType.value" type="radio" :value="drinkType">
                            <span>{{ drinkType }}</span>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            </i>
                        </label>
                    </div>
                </div>
                <span v-if="validation.errors.drinkType.value" class="form__error-text">{{ validation.errors.drinkType.value }}</span>

                <!-- Other drink type -->
                <div v-if="aboutDrinkStep.drinkType.value === trans.step.drinkType.types[trans.step.drinkType.types.length - 1]">
                    <input v-model.trim="aboutDrinkStep.drinkType.otherValue" id="cmd_otherType" type="text" :placeholder="trans.step.drinkType.labelOtherType">
                    <label for="cmd_otherType">{{ trans.step.drinkType.labelOtherType }}</label>
                    <span v-if="validation.errors.drinkType.otherValue" class="form__error-text">{{ validation.errors.drinkType.otherValue }}</span>
                </div>
            </div>

            <!-- 2. Flavour -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.multipleFlavours.title }}</h4>
                        <tooltip v-if="trans.step.multipleFlavours.tooltip.isEnable" :title="trans.step.multipleFlavours.tooltip.title" :content="trans.step.multipleFlavours.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.hasMultipleFlavor }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="hasMultipleFlavor_yes">
                                <input v-model="aboutDrinkStep.hasMultipleFlavor" id="hasMultipleFlavor_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="hasMultipleFlavor_no">
                                <input v-model="aboutDrinkStep.hasMultipleFlavor" id="hasMultipleFlavor_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.hasMultipleFlavor" class="form__error-text">{{ validation.errors.hasMultipleFlavor }}</span>
                </div>
            </div>

            <!-- 3. Gaseous -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.isGaseous.title }}</h4>
                        <tooltip v-if="trans.step.isGaseous.tooltip.isEnable" :title="trans.step.isGaseous.tooltip.title" :content="trans.step.isGaseous.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.isGaseous }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="isGaseous_yes">
                                <input v-model="aboutDrinkStep.isGaseous" id="isGaseous_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="isGaseous_no">
                                <input v-model="aboutDrinkStep.isGaseous" id="isGaseous_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.isGaseous" class="form__error-text">{{ validation.errors.isGaseous }}</span>
                </div>
            </div>

            <!-- 4. Raw material -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.rawMaterial.title }}</h4>
                        <tooltip v-if="trans.step.rawMaterial.tooltip.isEnable" :title="trans.step.rawMaterial.tooltip.title" :content="trans.step.rawMaterial.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.hasRawMaterial.value }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="hasRawMateriel_yes">
                                <input v-model="aboutDrinkStep.hasRawMaterial.value" id="hasRawMateriel_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="hasRawMateriel_no">
                                <input v-model="aboutDrinkStep.hasRawMaterial.value" id="hasRawMateriel_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.hasRawMaterial.value" class="form__error-text">{{ validation.errors.hasRawMaterial.value }}</span>
                </div>
            </div>
            <!-- CONDITIONAL -> wanted value -->
            <div v-if="aboutDrinkStep.hasRawMaterial.value === false" class="form__group" :class="{'form__error': validation.errors.hasRawMaterial.wantedValue }">
                <input v-model.trim="aboutDrinkStep.hasRawMaterial.wantedValue" id="cmd_hasRawMaterialAskedValue" type="text" :placeholder="trans.step.rawMaterial.wantedValue">
                <label for="cmd_hasRawMaterialAskedValue">{{ trans.step.rawMaterial.wantedValue }}</label>
                <span v-if="validation.errors.hasRawMaterial.wantedValue" class="form__error-text">{{ validation.errors.hasRawMaterial.wantedValue }}</span>
            </div>

            <!-- 5. Recipe -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                         <h4>{{ trans.step.recipe.title }}</h4>
                         <tooltip v-if="trans.step.recipe.tooltip.isEnable" :title="trans.step.recipe.tooltip.title" :content="trans.step.recipe.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.hasRecipe.value }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="hasRecipe_yes">
                                <input v-model="aboutDrinkStep.hasRecipe.value" id="hasRecipe_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="hasRecipe_no">
                                <input v-model="aboutDrinkStep.hasRecipe.value" id="hasRecipe_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.hasRecipe.value" class="form__error-text">{{ validation.errors.hasRecipe.value }}</span>
                </div>
            </div>
            <div v-if="aboutDrinkStep.hasRecipe.value === true" class="form__group" :class="{'form__error': validation.errors.hasRecipe.uploadedRecipe.name }">
                <span v-if="validation.errors.hasRecipe.uploadedRecipe.name" class="form__error-text form__error-dropzone">{{ validation.errors.hasRecipe.uploadedRecipe.name }}</span>
                <drop-zone @drop.prevent="dropRecipe" @change="changeRecipe" :label="trans.step.recipe.dropZoneLabel" :uploadedFileName="aboutDrinkStep.hasRecipe.uploadedRecipe.name" />
            </div>

            <!-- 6. Testing panel -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt specific-margin-top--sm">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.panel.title }}</h4>
                        <tooltip v-if="trans.step.panel.tooltip.isEnable" :title="trans.step.panel.tooltip.title" :content="trans.step.panel.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.needTestingPanel }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="needTestingPanel_yes">
                                <input v-model="aboutDrinkStep.needTestingPanel" id="needTestingPanel_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="needTestingPanel_no">
                                <input v-model="aboutDrinkStep.needTestingPanel" id="needTestingPanel_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.needTestingPanel" class="form__error-text">{{ validation.errors.needTestingPanel }}</span>
                </div>
            </div>

            <!-- 7. Laboratory analysis -->
            <div class="grid__row grid__row--jcsb form__pre-group--pt">
                <div class="form__pre-group">
                    <div class="util--flex">
                        <h4>{{ trans.step.analysis.title }}</h4>
                        <tooltip v-if="trans.step.analysis.tooltip.isEnable" :title="trans.step.analysis.tooltip.title" :content="trans.step.analysis.tooltip.description"/>
                    </div>
                </div>
                <div class="form__group" :class="{'form__error': validation.errors.hasLaboratoryAnalysis }">
                    <div class="form__selectable form__selectable--inline">
                        <!-- Yes -->
                        <div class="form__selectable-item">
                            <label for="hasLaboratoryAnalysis_yes">
                                <input v-model="aboutDrinkStep.hasLaboratoryAnalysis" id="hasLaboratoryAnalysis_yes" type="radio" :value="true">
                                <span>{{ trans.yes }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                        <!-- No -->
                        <div class="form__selectable-item">
                            <label for="hasLaboratoryAnalysis_no">
                                <input v-model="aboutDrinkStep.hasLaboratoryAnalysis" id="hasLaboratoryAnalysis_no" type="radio" :value="false">
                                <span>{{ trans.no }}</span>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </i>
                            </label>
                        </div>
                    </div>
                    <span v-if="validation.errors.hasLaboratoryAnalysis" class="form__error-text">{{ validation.errors.hasLaboratoryAnalysis }}</span>
                </div>
            </div>

            <!-- 8. Certifications -->
            <div class="form__pre-group form__pre-group--pt">
                <div class="util--flex">
                    <h4>{{ trans.step.certifications.title }}</h4>
                    <tooltip v-if="trans.step.certifications.tooltip.isEnable" :title="trans.step.certifications.tooltip.title" :content="trans.step.certifications.tooltip.description"/>
                </div>
                <span class="form__pre-group__info">{{ trans.step.certifications.clarification }}</span>
            </div>
            <div class="form__group">
                <!-- Available types -->
                <div class="form__selectable">
                    <div v-for="(certification, i) in trans.step.certifications.values" :key="i" class="form__selectable-item">
                        <label :for="'certification'+i">
                            <input :id="'certification'+i" v-model="aboutDrinkStep.neededCertifications" type="checkbox" :value="certification">
                            <span>{{ certification }}</span>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            </i>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        
        <step-footer @nextClicked="validate"/>
    </div>
</template>

<script>
import StepFooter from "@/create-my-drink/components/StepFooter";
import StepHeader from "@/create-my-drink/components/StepHeader";
import * as Yup from "yup";
import {validateStep} from "@/create-my-drink/lib";
import PriceSummary from "@/create-my-drink/components/PriceSummary";
import DropZone from "@/create-my-drink/components/DropZone";
import Tooltip from "@/create-my-drink/components/Tooltip";

export default {
    name: "AboutDrink",
    components: {Tooltip, DropZone, PriceSummary, StepHeader, StepFooter},
    data() {
        return {
            aboutDrinkStep: this.$store.state.form.steps.aboutDrink,
            trans: {
                step: this.$store.state.umbracoProps.aboutDrink,
                validation: this.$store.state.umbracoProps.others.validation,
                yes: this.$store.state.umbracoProps.others.yes,
                no: this.$store.state.umbracoProps.others.no,
            },
            maxUploadMb: 10,
            // Properties must have exactly the same name as the value in the form step storage
            validation: {
                validationSchema: null, // Must be generate in mounted() because of data dependencies 
                errors: {
                    drinkType: {
                        value: null,
                        otherValue: null
                    },
                    hasMultipleFlavor: null,
                    isGaseous: null,
                    hasRawMaterial: {
                        value: null,
                        wantedValue: null
                    },
                    hasRecipe: {
                        value: null,
                        uploadedRecipe: {
                            name: null,
                            bytes: null
                        },
                    },
                    needTestingPanel: null,
                    hasLaboratoryAnalysis: null
                }
            },
        }
    },
    mounted() {
        window.scrollTo({
            top: 0
        })

        // generate the validation schema that have some data() dependencies 
        this.validation.validationSchema = Yup.object().shape({
            drinkType: Yup.object().shape({
                value: Yup.string().nullable()
                    .required(this.trans.validation.required)
                    .oneOf(this.trans.step.drinkType.types, this.trans.validation.required),
                otherValue: Yup.string().nullable().when("value", {
                    is: this.trans.step.drinkType.types[this.trans.step.drinkType.types.length - 1],
                    then: Yup.string().nullable()
                        .required(this.trans.validation.required)
                        .max(255, this.trans.validation.tooLong),
                }),
            }),
            hasMultipleFlavor: Yup.boolean().nullable()
                .required(this.trans.validation.required),
            isGaseous: Yup.boolean().nullable()
                .required(this.trans.validation.required),
            hasRawMaterial: Yup.object().shape({
                value: Yup.boolean().nullable()
                    .required(this.trans.validation.required),
                wantedValue: Yup.string().nullable().when("value", {
                    is: false,
                    then: Yup.string().nullable()
                        .required(this.trans.validation.required)
                        .max(255, this.trans.validation.tooLong),
                }),
            }),
            hasRecipe: Yup.object().shape({
                value: Yup.boolean().nullable()
                    .required(this.trans.validation.required),
                uploadedRecipe: Yup.object().when('value', {
                    is: true,
                    then: Yup.object().shape({
                        name: Yup.string().nullable()
                            .test('uploadRecipeMustExists', this.trans.validation.required, () => { return this.aboutDrinkStep.hasRecipe.uploadedRecipe.name !== null })
                            .test('uploadRecipeMaxSize', this.trans.validation.uploadMaxSize, () => { 
                                let uploadedFile = document.getElementById("dropzoneFile").files[0]
                                if(uploadedFile === undefined) { // May be the case if we come back and there is an old uploaded file [no need to retest]
                                    return true
                                }else{
                                    return uploadedFile.size / 1024 / 1024 <= this.maxUploadMb
                                }
                            })
                    })
                })
                
            }),
            needTestingPanel: Yup.boolean().nullable()
                .required(this.trans.validation.required),
            hasLaboratoryAnalysis: Yup.boolean().nullable()
                .required(this.trans.validation.required)
        })
    },
    methods: {
        validate: function() {
            validateStep(this.validation.validationSchema, this.aboutDrinkStep, this.validation.errors, () => {
                // Store + persist data and pass to the next step at the same time
                this.$store.commit('nextStep')
            })
        },
        dropRecipe: function(e) {
            const file = e.dataTransfer.files[0]
            this.processDropRecipe(file)
        },
        changeRecipe: function() {
            const file = document.getElementById("dropzoneFile").files[0]
            this.processDropRecipe(file)
        },
        processDropRecipe: function(file) {
            if(file !== undefined) {
                const reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = () => {
                    const base64EncodedFile = btoa(reader.result)
                    this.aboutDrinkStep.hasRecipe.uploadedRecipe.name = file.name
                    this.aboutDrinkStep.hasRecipe.uploadedRecipe.base64Encoded = base64EncodedFile
                }
            }
        }
    }
}
</script>

<style scoped>

</style>