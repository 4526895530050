<template>
    <div class="stepper">
        <!-- Previous button -->
        <button @click="previousStep" class="stepper__previousBtn" :class="{ 'util--invisible': $store.state.form.currentStep <= 1 }">
            <svg xmlns="http://www.w3.org/2000/svg" width="23.468" height="17.709"><path fill="none" stroke="#000" stroke-width="2" d="M1.488 12.015h15.578M12.432 1h4.638" data-name="Tracé 341"/><path fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2" d="m6.424 7.029-5.007 4.985L6.424 17" data-name="Tracé 342"/><path fill="none" stroke="#000" stroke-width="2" d="M16.96 12.015A5.508 5.508 0 1 0 16.96 1" data-name="Path 514"/></svg>
            {{ trans.stepper.previous }}
        </button>
        <!-- Remaining steps -->
        <div v-if="$store.state.form.currentStep < steps.length" class="stepper__steps">
            <span>{{ trans.stepper.step }} {{ $store.state.form.currentStep }} {{ trans.stepper.on }} {{ steps.length-1 }}</span>
            <div class="stepper__steps-bottles">
                <div v-for="stepIndex in steps.length-1" :key="stepIndex">
                    <div class="stepper__steps-bottle" :class="{ 'current-step': stepIndex === $store.state.form.currentStep }">
                        <!-- Current step -->
                        <svg v-if="stepIndex === $store.state.form.currentStep" xmlns="http://www.w3.org/2000/svg" width="14.148" height="33.835"><path d="M0 9.691V31.2a2.643 2.643 0 0 0 2.642 2.637h8.864a2.643 2.643 0 0 0 2.642-2.637V9.691a.423.423 0 0 0-.1-.273L10.14 4.8a1.116 1.116 0 0 0 .727-1.043v-2.64A1.118 1.118 0 0 0 9.75 0H4.4a1.118 1.118 0 0 0-1.119 1.117v2.641A1.116 1.116 0 0 0 4.008 4.8L.1 9.418a.423.423 0 0 0-.1.273Zm4.127-8.574A.271.271 0 0 1 4.4.846h5.35a.271.271 0 0 1 .271.271v2.641a.271.271 0 0 1-.271.271H4.4a.271.271 0 0 1-.271-.271ZM.846 9.846l4.208-4.971h4.04L11.821 8.1 13.3 9.846V31.2a1.8 1.8 0 0 1-1.8 1.791H2.642A1.8 1.8 0 0 1 .846 31.2Z"/><path d="M.168 26.142s4.625-1.113 7.344.206 6.4-.206 6.4-.206v5.651c0 .705-1.231 1.277-2.75 1.277H2.001a1.142 1.142 0 0 1-1.189-1.277Z" data-name="Path 520"/></svg>
                        <!-- Completed steps -->
                        <svg v-else-if="stepIndex < $store.state.form.currentStep" xmlns="http://www.w3.org/2000/svg" width="14.148" height="33.835"><path d="M0 9.691V31.2a2.643 2.643 0 0 0 2.642 2.637h8.864a2.643 2.643 0 0 0 2.642-2.637V9.691a.423.423 0 0 0-.1-.273L10.14 4.8a1.116 1.116 0 0 0 .727-1.043v-2.64A1.118 1.118 0 0 0 9.75 0H4.4a1.118 1.118 0 0 0-1.119 1.117v2.641A1.116 1.116 0 0 0 4.008 4.8L.1 9.418a.423.423 0 0 0-.1.273Zm4.127-8.574A.271.271 0 0 1 4.4.846h5.35a.271.271 0 0 1 .271.271v2.641a.271.271 0 0 1-.271.271H4.4a.271.271 0 0 1-.271-.271ZM.846 9.846l4.208-4.971h4.04L11.821 8.1 13.3 9.846V31.2a1.8 1.8 0 0 1-1.8 1.791H2.642A1.8 1.8 0 0 1 .846 31.2Z"/><path d="M.234 9.78s4.625-1.113 7.344.206 6.4-.206 6.4-.206l-.229 22.014c0 .705-1.231 1.277-2.75 1.277H1.834a1.142 1.142 0 0 1-1.189-1.277Z" data-name="Path 519"/></svg>
                        <!-- Incomplete steps -->
                        <svg v-else xmlns="http://www.w3.org/2000/svg" width="14.148" height="33.835"><path d="M0 9.691V31.2a2.643 2.643 0 0 0 2.642 2.637h8.864a2.643 2.643 0 0 0 2.642-2.637V9.691a.423.423 0 0 0-.1-.273L10.14 4.8a1.116 1.116 0 0 0 .727-1.043v-2.64A1.118 1.118 0 0 0 9.75 0H4.4a1.118 1.118 0 0 0-1.119 1.117v2.641A1.116 1.116 0 0 0 4.008 4.8L.1 9.418a.423.423 0 0 0-.1.273Zm4.127-8.574A.271.271 0 0 1 4.4.846h5.35a.271.271 0 0 1 .271.271v2.641a.271.271 0 0 1-.271.271H4.4a.271.271 0 0 1-.271-.271ZM.846 9.846l4.208-4.971h4.04L11.821 8.1 13.3 9.846V31.2a1.8 1.8 0 0 1-1.8 1.791H2.642A1.8 1.8 0 0 1 .846 31.2Z"/></svg>
                    </div>
                </div>
            </div>
        </div>
        <!-- Completed -->
        <div v-else class="stepper__steps--completed">
            <span>{{ trans.stepper.complete }} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Stepper",
    props: {
        steps: {
            type: Array,
            required: true
        }
    },
    data() {
      return {
          trans: {
              stepper: this.$store.state.umbracoProps.others.stepper
          }
      }  
    },
    methods: {
        previousStep: function() {
            this.$store.commit('previousStep')
        }
    }
}
</script>

<style scoped>
</style>