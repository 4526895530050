<template>
    <div class="step__footer">
        <!-- Next button -->
        <button v-if="$store.state.form.currentStep < steps.length" @click="nextClicked" class="btn btn--black">{{ $store.state.umbracoProps.others.stepper.next }} <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="18.528" height="18.627" xml:space="preserve"><path d="M0 8.314h16.994v2H0z"/><path d="m9.222 18.627-1.415-1.414L15.7 9.314l-7.893-7.9L9.222 0l9.306 9.314z"/></svg></button>
    </div>
</template>

<script>
export default {
    name: "StepFooter",
    inject: ['steps'],
    methods: {
        nextClicked: function() {
            this.$emit('nextClicked')
        }
    }
}
</script>

<style scoped>
</style>