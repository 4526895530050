<template>
    <div class="priceSummary">
        <!-- We have little "Open/close" toggle in sticky mode  -->
        <div v-if="stickyMode" class="priceSummary__sticky-toggle">
            <div v-if="!stickyIsOpen" @click="toggleStickyIsOpen" class="priceSummary__sticky-toggle-item">
                <span>{{ trans.priceSummary.detailsLabel }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
            </div>
            <div v-if="stickyIsOpen" @click="toggleStickyIsOpen" class="priceSummary__sticky-toggle-item">
                <span>{{ trans.priceSummary.closeLabel }}</span>
                <svg class="icon--rotate-180" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
            </div>
        </div>
        
        <div class="priceSummary__content" :class="{'util--hide-for-small-down': !stickyIsOpen && stickyMode}">
            <!-- Limit info in sticky mode when close  -->
            <div v-if="!stickyMode || stickyIsOpen">
                <div class="priceSummary__title">{{ trans.priceSummary.summaryLabel }}</div>
                <hr>

                <!-- Fix costs -->
                <div class="priceSummary__costBloc">
                    <div class="costBloc__title">
                        {{ trans.priceSummary.fixedCostsLabel }}
                    </div>
                    <div v-if="fixedCosts.activeItems.length > 0" v-for="fixedCostItem in fixedCosts.activeItems" class="costBloc__listItem">
                        <span>{{ fixedCostItem.title }}</span>
                        <span>{{ fixedCostItem.price }} {{ trans.priceSummary.deviseLabel }}</span>
                    </div>
                    <div v-else class="costBloc__listItem">
                        <span>{{ trans.priceSummary.noneLabel }}</span>
                    </div>
                </div>

                <!-- Variable costs -->
                <div v-if="!packageHalfDayIsApply" class="priceSummary__costBloc">
                    <div class="costBloc__title">
                        {{ trans.priceSummary.variableCostsLabel }}
                    </div>
                    <div class="costBloc__listItem">
                        <span>{{ trans.priceSummary.forLabel }} {{ bottlesQuantity }} {{ trans.priceSummary.bottlesPluralLabel }}</span>
                        <span>{{ pricePerBottleRounded }} {{ trans.priceSummary.deviseLabel }} / {{ trans.priceSummary.bottlesSingularLabel }}</span>
                    </div>
                </div>
            </div>

            <!-- Total cost -->
            <div class="priceSummary__total">
                <div class="priceSummary__totalTitle">
                    <span class="priceSummary__totalTitle--small">{{ trans.priceSummary.totalCostsLabel }}*</span>
                    <span class="priceSummary__totalTitle--big">{{ totalCosts }} {{ trans.priceSummary.deviseLabel }}</span>
                </div>
            </div>

            <!-- Conditions -->
            <div class="priceSummary__conditions">
                <span>* {{ trans.priceSummary.conditionsLabel }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    /**
     * This component is responsible for price calculation (variable or fix).
     * Calculation is made in two steps :
     *      1. In mounted() (when component is load) we re-calculate all the prices by travel all the form.
     *         Because we need to immediately have the whole prices updated
     *      2. We watch EACH attribute change on form that impact a price change
     *      
     * To add a new attribute that has a certain price, don't forget to add it in both place (mounted and watchers)
     */
    name: "PriceSummary",
    props: {
        stickyMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            stickyIsOpen: false,
            
            aboutDrinkStep: this.$store.state.form.steps.aboutDrink,
            productionLogisticsStep: this.$store.state.form.steps.productionLogistics,
            marketingCommunicationStep: this.$store.state.form.steps.marketingCommunication,
            
            trans: {
                priceSummary: this.$store.state.umbracoProps.others.priceSummary,
            },
            
            pricePerBottle: 0.0,
            packageHalfDayIsApply: false,
            
            variableCosts: {
                drinkType: this.$store.state.umbracoProps.others.variableCosts.drinkTypePrice,
                hasMultipleFlavor: this.$store.state.umbracoProps.others.variableCosts.multipleFlavorsPrice,
                isGaseous: this.$store.state.umbracoProps.others.variableCosts.isGaseousPrice,
                bottlesQuantity: {
                    lt2k: 0,
                    betw2k5k: this.$store.state.umbracoProps.others.variableCosts.bottlesBetw2k5kPrice,
                    betw5k10k: this.$store.state.umbracoProps.others.variableCosts.bottlesBetw5k10kPrice,
                    betw10k15k: this.$store.state.umbracoProps.others.variableCosts.bottlesBetw10k15kPrice,
                    betw15k20k: this.$store.state.umbracoProps.others.variableCosts.bottlesBetw15k20kPrice,
                    gt20k: this.$store.state.umbracoProps.others.variableCosts.bottlesGt20kPrice,
                },
                storageAmbientTemperature: this.$store.state.umbracoProps.others.variableCosts.storageAmbientPrice,
                needDesignerService: this.$store.state.umbracoProps.others.variableCosts.designerServicePrice,
            },

            fixedCosts: {
                activeItems: [],
                needRecipe: {
                    id: 1,
                    title: this.$store.state.umbracoProps.others.fixedCosts.needRecipeLabel,
                    price: this.$store.state.umbracoProps.others.fixedCosts.needRecipePrice,
                },
                needTestingPanel: {
                    id: 2,
                    title: this.$store.state.umbracoProps.others.fixedCosts.testingPanelLabel,
                    price: this.$store.state.umbracoProps.others.fixedCosts.testingPanelPrice,
                },
                needLaboratoryAnalysis: {
                    id: 3,
                    title: this.$store.state.umbracoProps.others.fixedCosts.analysisLabel,
                    price: this.$store.state.umbracoProps.others.fixedCosts.analysisPrice,
                },
                packageHalfDay: {
                    id: 4,
                    title: this.$store.state.umbracoProps.others.fixedCosts.halfDayPackageLabel,
                    price: this.$store.state.umbracoProps.others.fixedCosts.halfDayPackagePrice,
                },
                needNutritionalValue: {
                    id: 5,
                    title: this.$store.state.umbracoProps.others.fixedCosts.nutritionalLabel,
                    price: this.$store.state.umbracoProps.others.fixedCosts.nutritionalPrice,
                },
                needLegalValidation: {
                    id: 6,
                    title: this.$store.state.umbracoProps.others.fixedCosts.legalDataLabel,
                    price: this.$store.state.umbracoProps.others.fixedCosts.legalDataPrice,
                }
            }
        }
    },
    mounted() {
        this.calculateAllStepsPrices()
    },
    watch: {
        // ------ AboutDrink step ------
        '$store.state.form.steps.aboutDrink.drinkType.value': function(newVal, oldVal) {
            this.toggleVariableCostsIsNotNullVal(oldVal, newVal, this.variableCosts.drinkType)
        },
        '$store.state.form.steps.aboutDrink.hasMultipleFlavor': function(newVal, oldVal) {
            this.toggleVariableCostsExactVal(true, oldVal, newVal, this.variableCosts.hasMultipleFlavor)
        },
        '$store.state.form.steps.aboutDrink.isGaseous': function(newVal, oldVal) {
            this.toggleVariableCostsExactVal(true, oldVal, newVal, this.variableCosts.isGaseous)
        },
        '$store.state.form.steps.aboutDrink.hasRecipe.value': function(newVal) {
            this.toggleFixedCostsExactVal(false, newVal, this.fixedCosts.needRecipe)
        },
        '$store.state.form.steps.aboutDrink.needTestingPanel': function(newVal) {
            this.toggleFixedCostsExactVal(true, newVal, this.fixedCosts.needTestingPanel)
        },
        '$store.state.form.steps.aboutDrink.hasLaboratoryAnalysis': function(newVal) {
            this.toggleFixedCostsExactVal(false, newVal, this.fixedCosts.needLaboratoryAnalysis)
        },
        // ------ Production and logistics step ------
        '$store.state.form.steps.productionLogistics.bottlesQuantity': function(newVal, oldVal) {
            this.toggleVariableCostsBottlesQuantity(oldVal, newVal)
        },
        '$store.state.form.steps.productionLogistics.needStorageRawMaterial.temperatureType': function(newVal, oldVal) {
            this.toggleVariableCostsExactVal(this.$store.state.umbracoProps.productionLogistics.materialStorage.temperatureAmbient, oldVal, newVal, this.variableCosts.storageAmbientTemperature)
        },
        '$store.state.form.steps.productionLogistics.needStorageFinalProduct.temperatureType': function(newVal, oldVal) {
            this.toggleVariableCostsExactVal(this.$store.state.umbracoProps.productionLogistics.materialStorage.temperatureAmbient, oldVal, newVal, this.variableCosts.storageAmbientTemperature)
        },
        '$store.state.form.steps.productionLogistics.needNutritionalValue': function(newVal, oldVal) {
            this.toggleFixedCostsExactVal(true, newVal, this.fixedCosts.needNutritionalValue)
        },
        // ------ Marketing and Communication step ------
        '$store.state.form.steps.marketingCommunication.hasOwnLabel.needDesigner': function(newVal, oldVal) {
            this.toggleVariableCostsExactVal(true, oldVal, newVal, this.variableCosts.needDesignerService)
        },
        '$store.state.form.steps.marketingCommunication.needLegalValidation': function(newVal, oldVal) {
            this.toggleFixedCostsExactVal(true, newVal, this.fixedCosts.needLegalValidation)
        },
    },
    methods: {
        // ------ Toggle variable costs item - Utility functions ------
        toggleVariableCostsExactVal: function(expectedBoolVal, oldVal, newVal, priceVariation){
            if(!this.packageHalfDayIsApply){
                // Add the price when the boolean is true
                if(newVal === expectedBoolVal){
                    this.pricePerBottle += priceVariation
                }

                // Remove the price only when the bool was true and pass to false now
                if(oldVal === expectedBoolVal){
                    this.pricePerBottle -= priceVariation
                }
            }
        },
        toggleVariableCostsIsNotNullVal: function(oldVal, newVal, priceVariation) {
            if(!this.packageHalfDayIsApply) {
                if (oldVal === null && newVal !== null) {
                    this.pricePerBottle += priceVariation
                }
            }
        },
        toggleVariableCostsBottlesQuantity: function(oldVal, newVal){
            if(newVal === 0){
                this.applyPackageHalfDay()
            }else if(this.packageHalfDayIsApply){
                this.removePackageHalfDay()
            }else{
                this.pricePerBottle -= this.fetchPriceVariationByBottlesQuantity(oldVal)
                this.pricePerBottle += this.fetchPriceVariationByBottlesQuantity(newVal)   
            }
        },
        // ------ Toggle fixed costs item - Utility functions ------
        toggleFixedCostsExactVal: function(expectedVal, val, fixPriceItem){
            if(!this.packageHalfDayIsApply) {
                const itemFoundIndex = this.fixedCosts.activeItems.findIndex(item => item.id === fixPriceItem.id)
                // Item already in the active array
                if (itemFoundIndex !== -1) {
                    // Remove it only if value is not the expected one
                    if (val !== expectedVal) {
                        this.fixedCosts.activeItems.splice(itemFoundIndex, 1)
                    }
                }
                // Item is not in the active array
                else {
                    if (val === expectedVal) {
                        this.fixedCosts.activeItems.push(fixPriceItem)
                    }
                }
            }
        },
        
        
        // ------ Calculate instant prices for all steps ------
        calculateAllStepsPrices(){
            this.calculateAboutDrinkStepPrices()
            this.calculateProductionLogisticsStepPrices()
            this.calculateMarketingCommunicationStepPrices()
        },
        calculateAboutDrinkStepPrices(){
            // Drink type (variable cost)
            this.toggleVariableCostsIsNotNullVal(null, this.aboutDrinkStep.drinkType.value, this.variableCosts.drinkType)
            // Has multiple flavor (variable cost)
            this.toggleVariableCostsExactVal(true, null, this.aboutDrinkStep.hasMultipleFlavor, this.variableCosts.hasMultipleFlavor)
            // Is gaseous (variable cost)
            this.toggleVariableCostsExactVal(true, null, this.aboutDrinkStep.isGaseous, this.variableCosts.isGaseous)
            // No recipe (fixed cost)
            this.toggleFixedCostsExactVal(false, this.aboutDrinkStep.hasRecipe.value, this.fixedCosts.needRecipe)
            // Want testing panel (fixed cost)
            this.toggleFixedCostsExactVal(true, this.aboutDrinkStep.needTestingPanel, this.fixedCosts.needTestingPanel)
            // Need laboratory analysis
            this.toggleFixedCostsExactVal(false, this.aboutDrinkStep.hasLaboratoryAnalysis, this.fixedCosts.needLaboratoryAnalysis)
        },
        calculateProductionLogisticsStepPrices(){
            // Bottles quantities (variable costs [and possibly fixed])
            this.toggleVariableCostsBottlesQuantity(null, this.productionLogisticsStep.bottlesQuantity)
            // Raw material Storage temperature type (variable costs)
            this.toggleVariableCostsExactVal(this.$store.state.umbracoProps.productionLogistics.materialStorage.temperatureAmbient, null, this.productionLogisticsStep.needStorageRawMaterial.temperatureType, this.variableCosts.storageAmbientTemperature)
            // Final product Storage temperature type (variable costs)
            this.toggleVariableCostsExactVal(this.$store.state.umbracoProps.productionLogistics.materialStorage.temperatureAmbient, null, this.productionLogisticsStep.needStorageFinalProduct.temperatureType, this.variableCosts.storageAmbientTemperature)
            // Need nutritional value (fixed cost)
            this.toggleFixedCostsExactVal(true, this.productionLogisticsStep.needNutritionalValue, this.fixedCosts.needNutritionalValue)
        },
        calculateMarketingCommunicationStepPrices(){
            // Has own label -> need designer servive (variable costs)
            this.toggleVariableCostsExactVal(true, null, this.marketingCommunicationStep.hasOwnLabel.needDesigner, this.variableCosts.needDesignerService)
            // Need legal validation
            this.toggleFixedCostsExactVal(true, this.marketingCommunicationStep.needLegalValidation, this.fixedCosts.needLegalValidation)
        },


        // ------ Helpers ------
        fetchPriceVariationByBottlesQuantity: function(quantity) {
            switch (quantity) {
                case 0:
                    return this.variableCosts.bottlesQuantity.lt2k
                case 2000:
                    return this.variableCosts.bottlesQuantity.betw2k5k
                case 5000:
                    return this.variableCosts.bottlesQuantity.betw5k10k
                case 10000:
                    return this.variableCosts.bottlesQuantity.betw10k15k
                case 15000:
                    return this.variableCosts.bottlesQuantity.betw15k20k
                case 20000:
                    return this.variableCosts.bottlesQuantity.gt20k
                default:
                    return 0
            }
        },
        /**
         * Half day package is a specific package. This will be the only one fix costs
         * and remove all variable costs and there will be no bottles quantity.
         */
        applyPackageHalfDay: function() {
            this.packageHalfDayIsApply = true
            // Keep only half day package in fix costs
            this.fixedCosts.activeItems = new Array(this.fixedCosts.packageHalfDay)
            // Cancel variable costs
            this.pricePerBottle = 0.0
        },
        /**
         * Remove the half day package and recalculate all the prices (fixed or variable)
         */
        removePackageHalfDay() {
            this.packageHalfDayIsApply = false
            this.fixedCosts.activeItems = new Array(0)
            this.calculateAllStepsPrices()  
        },
        /**
         * Round the val to the nearest 0.05
         */
        round005: function(val){
            return (Math.fround(val*20)/20).toFixed(2)
        },
        toggleStickyIsOpen: function(){
            this.stickyIsOpen = !this.stickyIsOpen
        }
    },
    computed: {
        pricePerBottleRounded: function() {
            return this.round005(this.pricePerBottle)  
        },
        totalCosts: function(){
            const totalFixedCosts = this.fixedCosts.activeItems.reduce((partialSum, item) => partialSum + item.price, 0)
            const totalVariableCosts = this.pricePerBottle * this.bottlesQuantity
            return this.round005(totalFixedCosts + totalVariableCosts)
        },
        bottlesQuantity: function() {
            return this.$store.state.form.steps.productionLogistics.bottlesQuantity
        }
    }
}
</script>

<style scoped>

</style>