<template>
     <div class="step">
         <step-header  :step-title="transStep.titles.stepTitle" :catch-phrase="transStep.titles.catchPhrase"/>

            <div class="form">
                 <div class="form__row">
                     <!-- Lastname -->
                     <div class="form__group" :class="{'form__error': validation.errors.lastname }">
                         <input v-model.trim="generalStep.lastname" id="cmd_lastname" type="text" :placeholder="transStep.labelLastname">
                         <label for="cmd_lastname">{{ transStep.labelLastname }}</label>
                         <span v-if="validation.errors.lastname" class="form__error-text">{{ validation.errors.lastname }}</span>
                     </div>
                     <!-- Firstname -->
                     <div class="form__group" :class="{'form__error': validation.errors.firstname }">
                         <input v-model.trim="generalStep.firstname" id="cmd_firstname" type="text" :placeholder="transStep.labelFirstname">
                         <label for="cmd_firstname">{{ transStep.labelFirstname }}</label>
                         <span v-if="validation.errors.firstname" class="form__error-text">{{ validation.errors.firstname }}</span>
                     </div>
                 </div>
               
                <!-- Email -->
                 <div class="form__group" :class="{'form__error': validation.errors.email }">
                     <input v-model.trim="generalStep.email" id="cmd__email" type="email" :placeholder="transStep.labelEmail">
                     <label for="cmd__email">{{ transStep.labelEmail }}</label>
                     <span v-if="validation.errors.email" class="form__error-text">{{ validation.errors.email }}</span>
                 </div>
              
                 <div class="form__row">
                     <!-- Brand name -->
                     <div class="form__group" :class="{'form__error': validation.errors.brandName }">
                         <input v-model.trim="generalStep.brandName" id="cmd_brandName" type="text" :placeholder="transStep.labelBrandName">
                         <label for="cmd_brandName">{{ transStep.labelBrandName }}</label>
                         <span v-if="validation.errors.brandName" class="form__error-text">{{ validation.errors.brandName }}</span>
                     </div>
                     <!-- Phone -->
                     <div class="form__group" :class="{'form__error': validation.errors.phone }">
                         <input v-model.trim="generalStep.phone" id="cmd_phone" type="tel" :placeholder="transStep.labelPhone">
                         <label for="cmd_phone">{{ transStep.labelPhone }}</label>
                         <span v-if="validation.errors.phone" class="form__error-text">{{ validation.errors.phone }}</span>
                     </div>
                 </div>
            </div>

         <step-footer @nextClicked="validate"/>
     </div>
</template>

<script>
import StepFooter from "@/create-my-drink/components/StepFooter";
import StepHeader from "@/create-my-drink/components/StepHeader";
import * as Yup from "yup"
import {validateStep} from "@/create-my-drink/lib";
export default {
    name: "General",
    components: {StepHeader, StepFooter},
    data() {
        return {
            generalStep: this.$store.state.form.steps.general,
            transStep: this.$store.state.umbracoProps.general,
            // Properties must have exactly the same name as the value in the generalStep storage
            validation: {
                validationSchema: Yup.object().shape({
                    lastname: Yup.string().nullable()
                        .required(this.$store.state.umbracoProps.others.validation.required)
                        .max(255, this.$store.state.umbracoProps.others.validation.tooLong),
                    firstname: Yup.string().nullable()
                        .required(this.$store.state.umbracoProps.others.validation.required)
                        .max(255, this.$store.state.umbracoProps.others.validation.tooLong),
                    email: Yup.string().nullable()
                        .required(this.$store.state.umbracoProps.others.validation.required)
                        .max(320, this.$store.state.umbracoProps.others.validation.tooLong)
                        .email(this.$store.state.umbracoProps.others.validation.email),
                    brandName: Yup.string().nullable()
                        .required(this.$store.state.umbracoProps.others.validation.required)
                        .max(255, this.$store.state.umbracoProps.others.validation.tooLong),
                    // Phone as string to be able to match regex only digit and number max of digit 
                    phone: Yup.string().nullable()
                        .required(this.$store.state.umbracoProps.others.validation.required)
                        .matches(/^[0-9]+$/, this.$store.state.umbracoProps.others.validation.onlyNumber)
                        .max(20, this.$store.state.umbracoProps.others.validation.tooLong)
                }),
                errors: {
                    lastname: null,
                    firstname: null,
                    email: null,
                    brandName: null,
                    phone: null
                } 
            }
        }
    },
    mounted() {
        window.scrollTo({
            top: 0
        })
    },
    methods: {
        validate: function() {
            validateStep(this.validation.validationSchema, this.generalStep, this.validation.errors, () => {
                // Store + persist data and pass to the next step at the same time
                this.$store.commit('nextStep')
            })
        }
    }
}
</script>

<style scoped>

</style>