<template>
    <div class="create-my-drink-page">
        
        <div v-if="umbracoPropsLoaded">
            <!-- Introduction page if never begin to fill the form -->
            <div v-if="$store.state.form.currentStep === 0" class="container">
                <div class="grid__row grid__row--jcc">
                    <div class="grid__col grid__col--md-10">
                        <div class="create-my-drink-page__title-wrapper">
                            <h1 class="h2-like create-my-drink-page__title">{{ $store.state.umbracoProps.introduction.title }}</h1>
                            <button @click="beginClicked" class="btn btn--white create-my-drink-page__title-btn">{{ $store.state.umbracoProps.introduction.button }} <i class="fas fa-arrow-right icon"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Create your drink form -->
            <div v-else>
                <div class="container">

                    <!-- Stepper -->
                    <stepper :steps="steps"/>

                    <!-- Display current step -->
                    <div class="grid__row grid__row--jcc">
                        <div class="grid__col--sm-10 grid__col--xs-12">
                            <div v-if="$store.state.form.currentStep === 1">
                                <general/>
                            </div>
                            <div v-else-if="$store.state.form.currentStep === 2">
                                <about-drink/>
                            </div>
                            <div v-else-if="$store.state.form.currentStep === 3">
                                <production-logistics/>
                            </div>
                            <div v-else-if="$store.state.form.currentStep === 4">
                                <marketing-communication/>
                            </div>
                            <div v-else-if="$store.state.form.currentStep === 5">
                                <investment-incubation/>
                            </div>
                            <div v-else-if="$store.state.form.currentStep === 6">
                                <summary-estimate/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Sticky price summary -> will be always visible at bottom of screen -->
        <div id="priceSummary--sticky">
            <price-summary v-if="umbracoPropsLoaded && $store.state.form.currentStep !== 0 && $store.state.form.currentStep !== 6" :sticky-mode="true" />
        </div>
        
    </div>
    
</template>

<script>
import Stepper from "@/create-my-drink/components/Stepper";
import General from "@/create-my-drink/steps/General";
import AboutDrink from "@/create-my-drink/steps/AboutDrink";
import ProductionLogistics from "@/create-my-drink/steps/ProductionLogistics";
import MarketingCommunication from "@/create-my-drink/steps/MarketingCommunication";
import InvestmentIncubation from "@/create-my-drink/steps/InvestmentIncubation";
import SummaryEstimate from "@/create-my-drink/steps/SummaryEstimate";
import PriceSummary from "@/create-my-drink/components/PriceSummary";
import axios from "axios";

export default {
    name: "App",
    components: {
        PriceSummary,
        SummaryEstimate, InvestmentIncubation, MarketingCommunication, ProductionLogistics, AboutDrink, General, Stepper},
    data() {
        return {
            umbracoPropsLoaded: false,
            /**
             * Step  [0]         : No yet start to open the form
             * Steps [1, n-1]    : Real steps of the form
             * Step  [n]         : Not really a step, it's the final resume page / billing etc. 
             */
            steps: [1,2,3,4,5,6],
        }
    },
    provide() {
        return {
            steps: this.steps
        }
    },
    mounted() {
        /** HACK
         * /!\  We must append the sticky price summary to the body (must be first child)  /!\
         * Because of the js-animation-wrapper div that cause problems with css position fixed... 
         */
        document.body.prepend(document.getElementById("priceSummary--sticky"));
        
        this.fetchAllUmbracoFormProperties()
    },
    methods: {
        beginClicked: function() {
            this.$store.commit('nextStep')
        },
        fetchAllUmbracoFormProperties: function() {
            const appEl = document.getElementById('app');
            const nodeId = appEl.dataset.nodeId;
            const lang = appEl.lang;
            
            axios.get('/umbraco/api/CreateDrink/Properties', {
                params: { 
                    nodeId: nodeId,
                    lang: lang
                }
            })
                .then((res) => {
                    this.$store.commit('setUmbracoProps', res.data)
                    
                    this.umbracoPropsLoaded = true
                })
            
        }
    }
}
</script>

<style scoped>
</style>