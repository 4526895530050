import { createApp } from "vue";
import CreateMyDrinkApp from "./create-my-drink/App";
import createMyDrinkStore from "@/create-my-drink/store";
import './assets/base.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

const createMyDrinkApplicationRoot = document.querySelector('div[id="app"][data-application="create-my-drink"]');

if(createMyDrinkApplicationRoot) {
    createApp(CreateMyDrinkApp)
        .use(createMyDrinkStore)
        .mount(createMyDrinkApplicationRoot)
    ;
}