<template>
    <span class="step__title">{{ stepTitle }}</span>
    <h3 class="step__catchphrase" v-if="catchPhrase !== null">{{ catchPhrase }}</h3>
</template>

<script>
export default {
    name: "StepHeader",
    props: {
        stepTitle: {
            type: String,
            required: true
        },
        catchPhrase: {
            type: String,
            required: false
        }
    }
}
</script>

<style scoped>

</style>